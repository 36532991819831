export const environment = {
  production: true,
  log: true,
  application: {
    name: "storefront.web",
    ssrEnabled: true,
    defaultStoreDomain: 'rexa.store',
    localServerPrivateIPs: '54.255.37.134,10.7.20.179',
    localServerUrls: 'localhost:4200,localhost:4000,,localhost:59901'
  },
  oAuthConfig: {
    issuer: 'https://rexacont.azurewebsites.net/',
    clientId: 'Whyzr_App',
    scope: 'offline_access Whyzr',
  },
  apis: {
    default: {
      url: 'https://rexa.azurewebsites.net',
      rootNamespace: 'Whyzr',
    },
  },
  firebase: {
    apiKey: "AIzaSyD4QPinoWCvnYyc10JKqFXcwPMPUGjXDWQ",
    authDomain: "emenu-45579.firebaseapp.com",
    projectId: "emenu-45579",
    storageBucket: "emenu-45579.appspot.com",
    messagingSenderId: "398692419449",
    appId: "1:398692419449:web:84096d237d0f9aa1d691a8",
    measurementId: "G-DRQFWR3NQ3"
  },
  storeId: '',
  googleAPI: 'AIzaSyA7Ad9HwiYIr6-063tnsl4bvoqXt7VztnE',
  ignoredefaultStoreConfig: true,
  provider: {
    name: 'EMENU',
    nameAr: 'منصة إيمينيو',
    logo: 'https://emenu.tech/wp-content/uploads/2021/09/%D8%A8%D8%AF%D9%88%D9%86-%D8%AE%D9%84%D9%81%D9%8A%D8%A9-2-1.png',
    website: 'https://emenu.tech',
    registrationUrl: 'https://my.emenu.tech/account/register'
  }
};