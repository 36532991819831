import { Component, Injector, Input, Optional } from '@angular/core';
import { DeliveryMethodsDesignOptions } from '@storefront/ng.themes/lib/themes/default/default-delivery-methods/models';
import { AddressDto, DeliveryMethodService, DeliveryMethodZoneRateStoreDto, DeliveryMethodZoneRatesRequest, DeliveryType } from '@tajer/api';
import { BaseComponent } from 'src/app/shared/components/base.component';
import { AddressAppService } from 'src/app/shared/app-services/address-app.service';
import { Subscription } from 'rxjs';
import { BSModalContext } from 'ngx-modialog-7/plugins/bootstrap';
import { DialogRef } from 'ngx-modialog-7';
import { CookieAppService } from 'src/app/shared/app-services/cookie-app.service';

@Component({
  selector: 'app-delivery-methods',
  templateUrl: './delivery-methods.component.html',
  styleUrls: ['./delivery-methods.component.scss'],
})
export class DeliveryMethodsComponent extends BaseComponent {
  //#region variables
  desginOptions: DeliveryMethodsDesignOptions;

  submited = false;
  viewDelivery = false;
  loading = true;
  loadingAddress;
  deliveryMethods: DeliveryMethodZoneRateStoreDto[];
  selectedDeliveryMethodId: string;
  deliveryAddress: any;
  addresses: AddressDto[];
  deliveryType = DeliveryType;
  public get selectedDeliveryMethod(): DeliveryMethodZoneRateStoreDto {
    let deliveryMethod = null;
    if (this.deliveryMethods) {
      deliveryMethod = this.deliveryMethods.find(x => x.id == this.selectedDeliveryMethodId);
    }
    return deliveryMethod;
  }
  public get showMethodMinimumLimitError(): boolean {
    if (this.selectedDeliveryMethod && this.cartAppService.shoppingCart && this.selectedDeliveryMethod.minimumDeliveryLimit) {
      return this.cartAppService.shoppingCart.subTotal < this.selectedDeliveryMethod.minimumDeliveryLimit;
    }
    return false;
  }
  subscribeCustomer :Subscription;
  @Input() isModal: boolean;
  @Input() editAddress: boolean;
  @Input() editDeliveryMethod: boolean
  //#end region variables

  constructor(injector: Injector,
    @Optional() public dialog: DialogRef<DeliveryMethodsComponentContext>,
    private addressAppService: AddressAppService,
    private deliveryMethodService: DeliveryMethodService){
    super(injector);
  }

  ngOnInit() {
    this.setModalContext();
    this.orderAppService.openCart();

    if (!this.cartAppService.shoppingCart) {
      this.router.navigate([this.getUrl('/cart')]);
      return;
    } else {
      if (this.orderAppService.order.deliveryType != DeliveryType.Delivery) {
        this.router.navigate([this.getUrl('/payment-methods')]);
        return;
      } else {
        if (this.customerAppService.hasLoggedIn) {
          this.getAddressess();
          this.subscribeCustomer=this.customerAppService.customer$.subscribe(
            data=>{
              this.getAddressess();
            }
          );
        } else {
          this.getDeliveryAddress();
          this.getDeliveryMethods();
        }
      }
    }

    this.populateMetaTags().then();
    if (this.isSegmentEnabled) { this.segmentService.implementEnterPageEvent("Order steps", "Delivery methods") }

    this.setIonicThemeOptions();
  }

  setIonicThemeOptions() {
    this.desginOptions = {
      color: this.storeDesignOptions?.color,
      frontColor: this.storeDesignOptions?.frontColor,
    };
  }

  setModalContext() {
    if (this.dialog) {
      this.isModal = this.dialog?.context.isDeliveryMethodsModal;
      this.editAddress= this.dialog?.context.editAddress;
      this.editDeliveryMethod= this.dialog?.context.editDeliveryMethod;
    }
  }

  getAddressess(){
    this.loadingAddress = true;
    this.addressAppService.getAddresses().then((response) => {
      this.addresses = response.items;
      if (this.addresses && this.addresses.length > 0 && !this.deliveryAddress) {
        this.setDeliveryAddress(this.addresses[0], true);
      } else {
        this.getDeliveryAddress();
        this.setDeliveryAddress(this.deliveryAddress, false);
      }
      this.loadingAddress = false;
    }, () => {
      this.loadingAddress = false;
    });
    this.loadingAddress = false;
  }

  getDeliveryMethods() {
    this.loading = true;
    if (this.deliveryAddress && this.addressAppService.isAddressValidPartially(this.deliveryAddress)) {
      this.viewDelivery = true;
      this.deliveryMethodService.getDeliveryMethodZoneRates({
        tenantId: this.isMarketplace? this.cookieAppService.getMarketTenantId() : null,
        outletId: this.isMarketplace? this.getMarketplaceCartOutletId(this.cookieAppService.getMarketTenantId()) : this.currentOutlet?.id,
        countryId: this.deliveryAddress?.countryId,
        stateProvinceId: this.deliveryAddress?.stateProvinceId,
        stateProvinceName: this.deliveryAddress?.stateProvinceName,
        cityId: this.deliveryAddress?.cityId,
        cityName: this.deliveryAddress?.cityName,
        districtId: this.deliveryAddress?.districtId,
        zipPostalCode: this.deliveryAddress?.zipPostalCode,
        currencyId: this.requestCurrencyId,
        deliveryMethodsIds: this.getDeliveryMethodsIds(),
        totalWeights: this.totalCartLinesWeights,
      } as DeliveryMethodZoneRatesRequest).subscribe((response) => {
        this.deliveryMethods = response;
        this.getDefaultDeliveryMethod();
        this.loading = false;
      },(response)=>{
        this.loading = false;
        this.customErrorMessage(response);
        if(this.isModal && !this.editAddress)
        this.close();
      });
    } else { 
      this.loading = false;  
      this.deliveryMethods=null;
     }
  }

  /**
   * if delivery methods on items enabled; then we get the delivery methods ids from
   * the card items in order to filter on them; else null
  */
  getDeliveryMethodsIds() {
    let deliveryMethodsIds: Set<string> = new Set<string>();
    if (this.configFeatures.deliveryMethods) {
      this.cartAppService.shoppingCart.cartLines.forEach(cartLine => {
        cartLine?.item?.deliveryMethodsIds?.forEach(deliveryMethodsId => {
          deliveryMethodsIds.add(deliveryMethodsId);
        });
      });
    }
    return Array.from(deliveryMethodsIds);
  }

  getDefaultDeliveryMethod() {
    // get default Delivery Method (first one)
    if (!this.orderAppService.deliveryMethodZoneRate) {
      if (this.deliveryMethods?.length > 0) {
        this.onSelectDeliveryMethod(this.deliveryMethods[0]);
        if (this.isSegmentEnabled) { this.segmentService.implementViewCheckoutFirstStepEvent(this.getValue(this.deliveryMethods[0], 'deliveryMethodName')) }
      }
    } else {
      if (this.isSegmentEnabled) { this.segmentService.implementViewCheckoutFirstStepEvent(this.getValue(this.orderAppService.deliveryMethodZoneRate, 'deliveryMethodName')) }
      this.onSelectDeliveryMethod(this.orderAppService.deliveryMethodZoneRate);
    }
  }

  getDeliveryAddress() {
    // get default Address from local storage
    const address = this.orderAppService.order.address;
    if (address) {
      this.deliveryAddress = address;
    }
  }

  
  async setDeliveryAddress(address: AddressDto, isAddress: boolean) {
    if (this.orderAppService.order.address && !this.editAddress) {
      this.getDeliveryAddress();
      this.getDeliveryMethods();
    } else {
      if (address) {
        this.deliveryAddress = address;
        if (!isAddress) {
          this.getDeliveryAddress();
        }
        this.getDeliveryMethods();
      }
    }
  }

  populateOrderAddress() {
    this.orderAppService.order.addressId = this.deliveryAddress.id ? this.deliveryAddress.id : null;
    this.orderAppService.order.address = this.deliveryAddress;
  }

  populateDeliveryMethods() {
    this.orderAppService.deliveryMethodZoneRate = this.selectedDeliveryMethod;
    this.orderAppService.order.deliveryMethodZoneRateId = this.selectedDeliveryMethod.id;
  }

  onSelectDeliveryMethod(deliveryMethodZoneRate: DeliveryMethodZoneRateStoreDto) {
    this.selectedDeliveryMethodId = deliveryMethodZoneRate.id;
    if (this.isSegmentEnabled) { this.segmentService.implementViewCheckoutFirstStepEvent(this.getValue(deliveryMethodZoneRate, 'deliveryMethodName')) }
  }

  submit() {
    this.submited = true;
    if (this.showMethodMinimumLimitError) {
      return;
    }
    if (this.addressAppService.isAddressValid(this.deliveryAddress) && this.selectedDeliveryMethodId && (this.deliveryAddress || this.deliveryAddress.id)) {
      this.submited = false;
      if (this.editAddress) {
        this.addressAppService.setTemp(true);
      }
      this.populateOrderAddress();
      this.populateDeliveryMethods();
      this.orderAppService.populateDeliveryFees();
      if (this.isSegmentEnabled) { this.segmentService.implementCompleteCheckoutFirstStepEvent(this.getValue(this.orderAppService.deliveryMethodZoneRate, 'deliveryMethodName')) }
      if (!this.storeOptions.enableQuickCheckout) {
        this.router.navigate([this.getUrl('/payment-methods')]);
      } else {
        this.close();
      }
    }
  }

  close() {
    this.addressAppService.setTemp();
    this.dialog?.close();
    if(this.isBrowser){
      document.body.classList.remove("modal-open");
    }  
  }

  populateMetaTags(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        const title = this.getPageTitle("DeliveryMethods");
        const description = null;
        const image = null;
        const url = this.getRequestFullUrl;
        this.htmlDocApp.SetMetas(title, description, url, image);
        resolve();
    });
  }

  get totalCartLinesWeights(){
    let totalWeight=0;
    this.cartAppService.shoppingCart?.cartLines?.forEach(l => {
      let itemDetail = l.item.itemDetails?.find(
        (details) => details.id === l.itemDetailId
      );
      totalWeight += l.quantity * itemDetail.weight;
    });
    return totalWeight;
  }

  ngOnDestroy(): void {
    this.subscribeCustomer?.unsubscribe();
  }

}

export class DeliveryMethodsComponentContext extends BSModalContext {
  constructor() {
    super();
  }

  isDeliveryMethodsModal: boolean;
  editAddress: boolean;
  editDeliveryMethod: boolean;
}
